import React from "react";
import {LoadingSpinner} from "../../Components/LoadingSpinner";
import {Redirect, RouteComponentProps} from "react-router";
import {observable} from "mobx";
import {inject, observer} from "mobx-react";
import {Api} from "../../Api/ApiManager";
import {Icon} from "antd";
import {OAuthContainer} from "../../Utils/OAuth/OAuthContainer";
import {AppState} from "../../AppState";

type PageParams = {
    provider?: string;
}

interface OAuthProps extends RouteComponentProps<PageParams> {
    appState?: AppState;
    oauthProviders?: OAuthContainer;
}

@inject("appState")
@inject("oauthProviders")
@observer
export class OAuth extends React.PureComponent<OAuthProps> {
    @observable private redirect = false;
    @observable private isSuccess = false;
    @observable private hasError = false;
    @observable private errorMessage?: string;

    private redirectTime = 3;

    render(): React.ReactElement {
        if(this.redirect) {
            return <Redirect to={`/account`} />
        }

        if (this.hasError) {
            return (
                <div style={{display: "flex", flexDirection: "column"}}>
                    <Icon type="exclamation-circle" style={{fontSize: "96px", color: "red", marginBottom: "12px"}}/>
                    <p style={{fontSize: "18px", color: "red", textAlign: "center"}}>{this.errorMessage}</p>
                </div>
            );
        }

        if (this.isSuccess) {
            return (
                <div style={{display: "flex", flexDirection: "column"}}>
                    <Icon type="check-circle" style={{fontSize: "96px", color: "green", marginBottom: "12px"}}/>
                    <p style={{fontSize: "18px", color: "green", textAlign: "center"}}>Success! You will be redirected within {this.redirectTime} seconds...</p>
                </div>
            );
        }

        return <LoadingSpinner loading={true}/>;
    }

    componentDidMount(): void {
        const provider = this.props.match.params.provider;
        if (! provider || ! this.props.oauthProviders!.isValidProvider(provider.toLowerCase())) {
            this.hasError = true;
            this.errorMessage = "Unknown provider given";

            return;
        }

        const urlSplit = this.props.history.location.search.split("?");
        if (urlSplit.length !== 2) {
            this.hasError = true;
            this.errorMessage = "Missing parameters";

            return;
        }

        const query = OAuth.parseQueryParameters(urlSplit[1]);
        if (!query.code) {
            this.hasError = true;
            this.errorMessage = "Invalid parameter format";

            return;
        }

        const oAuthProvider = this.props.oauthProviders!.getProvider(provider.toLowerCase());

        Api.session.oauth(provider, query.code.value, oAuthProvider.getRedirectUrl())
            .then((response) => {
                if (response.isOk) {
                    this.props.appState!.setSession(response.body.data);
                    this.isSuccess = true;
                    this.startRedirect();
                } else {
                    this.hasError = true;
                    this.errorMessage = "Unknown server error";
                }
            });
    }

    private static parseQueryParameters(paramString: string): {[key: string]: {key: string, value: string}} {
        return paramString.split("&")
            .map((q) => {
                const n = q.split("=");
                return {key: n[0], value: n[1]};
            })
            .reduce((acc: {[key: string]: {key: string, value: string}}, q) => {
                acc[q.key] = q;
                return acc;
            }, {});
    }

    private startRedirect() {
        setTimeout(() => this.redirect = true, this.redirectTime * 1000);
    }
}
