import React from "react";
import WrappedCreateAccountForm, {CreateUserFormValues} from "./CreateUserForm";
import {observable} from "mobx";
import {Redirect} from "react-router-dom"
import {Api} from "../../Api/ApiManager";
import {FormComponentProps} from "antd/es/form";
import {inject, observer} from "mobx-react";
import {OAuthContainer} from "../../Utils/OAuth/OAuthContainer";
import {OAuthProvider} from "../../Utils/OAuth/OAuthProvider";
import {AppState} from "../../AppState";
import {Routing} from "../../Utils/Routing";

interface CreateUserProps extends FormComponentProps {
    appState?: AppState;
    oauthProviders?: OAuthContainer;
}

@inject('appState')
@inject("oauthProviders")
@observer
export default class CreateUser extends React.Component<CreateUserProps> {
    @observable redirectToAccountPage?: number;

    render(): React.ReactElement {
        if (this.redirectToAccountPage) {
            return <Redirect to={Routing.account} />
        }

        return (
            <React.Fragment>
                <div style = {{display: 'flex', justifyContent: 'center'}}>
                    <WrappedCreateAccountForm onFormValid={this.onFormValid.bind(this)}/>
                </div>
                <div style={{display: 'flex', flexDirection: "column", alignItems: "center"}}>
                    {this.props.oauthProviders!.map(([_, provider], i) => (
                        <div key={i} style={{marginBottom: "8px"}}>
                            {provider.createSignupElement(this.startOAuthFlow.bind(this))}
                        </div>
                    ))}
                </div>
            </React.Fragment>
        );
    }

    private startOAuthFlow(provider: OAuthProvider) {
        window.location.href = provider.getAuthorizationUrl();
    }

    private async onFormValid(values: CreateUserFormValues) {
        const response = await Api.user.createUser({
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            password: values.password,
        });

        if (response.isOk) {
            const loginResponse = await Api.session.login(values.email, values.password);
            if (loginResponse.isOk) {
                this.props.appState!.setSession(loginResponse.body.data);
                this.redirectToAccountPage = loginResponse.body.data.user.id;
            } else {
                if(response.isClientError) {
                    alert("Woops! Something went wrong logging you in. Try again");
                } else {
                    alert("Woops! Failed to log you in. Try again later");
                }
            }
        } if(response.isClientError) {
            alert("Woops! Something went wrong creating user. Try again");
        } else {
            alert("Woops! Failed to create user. Try again later");        }
    }
}