import React, {Fragment} from 'react';
import {Button, Input, Modal, PageHeader, Popconfirm, Typography} from "antd";
import {observer} from "mobx-react";
import DropDown, {DropdownMenuItem} from './DropDown';
import {observable} from "mobx";
import {ClickToEdit} from "../../../Components/ClickToEdit";
import {TranslationKey} from "../../../Models/Translation";
import {CreateTranslationForm} from "./CreateTranslationForm";
import {RouteComponentProps} from "react-router";
import {LoadingSpinner} from "../../../Components/LoadingSpinner";
import {ProjectSingleViewModel} from "./ProjectSingleViewModel";

const {Text} = Typography;
const {TextArea} = Input;

type PageParams = {
    id?: string;
}

type TranslationListProps = {
} & RouteComponentProps<PageParams>;

@observer
export default class ProjectSingle extends React.Component<TranslationListProps> {
    @observable
    private viewModel = new ProjectSingleViewModel();

    private languages = [
        {
            key: "en",
            text: "English",
        },
        {
            key: "da",
            text: "Danish",
        },
        {
            key: "de",
            text: "German",
        },
    ];

    @observable
    private selection1: DropdownMenuItem = this.languages[0];

    @observable
    private selection2: DropdownMenuItem = this.languages[1];

    @observable
    private showCreateModal = false;

    @observable
    private isCreateLoading = false;

    @observable
    private submitForm = false;

    @observable
    private autoTranslateKey?: string;

    private get projectId(): number {
        return parseInt(this.props.match.params.id!);
    }

    render(): React.ReactElement {
        if (this.viewModel.isLoading) {
            return <LoadingSpinner loading={true}/>
        } else if (this.viewModel.isError) {
            return <p style={{color: "red", textAlign: "center"}}>Something went wrong...</p>
        }
        
        return (
            <Fragment>
                <PageHeader title={"Translations"}/>

                <div style={{marginBottom: "24px"}}>
                    <DropDown items={this.languages} selected={this.selection1} itemWasSelected={(item) => this.selection1 = item}/>
                    <Text style={{margin: "0px 4px"}}>to</Text>
                    <DropDown items={this.languages} selected={this.selection2} itemWasSelected={(item) => this.selection2 = item}/>
                </div>

                {this.viewModel.translationKeys.map((translationKey) => (
                    <div key={translationKey.key} style={{marginTop: "12px"}}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <Popconfirm
                                title="Are you sure delete this?"
                                onConfirm={() => this.deleteTranslation(translationKey.key)}
                                okText="Yes"
                                cancelText="No"
                                placement="topLeft"
                            >
                                <Button icon="delete" type="link" style={{color: "red"}}/>
                            </Popconfirm>
                            <ClickToEdit value={translationKey.key} onEndEdit={(newValue) => this.updateTranslationKey(newValue, translationKey.key)}/>
                        </div>

                        <div style={{display:"flex", justifyContent: "space-between"}}>
                            <TextArea style={{width: "48%"}} placeholder={"Empty..."}
                                      value={this.getTranslationForLang(this.selection1.key, translationKey)}
                                      onChange={(e) => this.onTextUpdated(e, translationKey, this.selection1.key)}
                                      onBlur={(e) => this.onTextBlur(e, translationKey, this.selection1.key)}/>

                            <TextArea style={{width: "48%"}} placeholder={"Empty..."}
                                      value={this.getTranslationForLang(this.selection2.key, translationKey)}
                                      onChange={(e) => this.onTextUpdated(e, translationKey, this.selection2.key)}
                                      onBlur={(e) => this.onTextBlur(e, translationKey, this.selection2.key)}/>
                        </div>

                        <div style={{display: "flex", justifyContent: "flex-end", marginTop: "4px"}}>
                            <Button loading={this.autoTranslateKey === translationKey.key} onClick={() => this.autoTranslate(translationKey)}>Auto translate</Button>
                        </div>
                    </div>
                ))}

                <Button style={{marginTop: "12px"}} onClick={this.addTranslation.bind(this)}>Add translation</Button>

                <Modal
                    visible={this.showCreateModal}
                    title="Title"
                    onOk={this.saveTranslation.bind(this)}
                    onCancel={this.closeModal.bind(this)}
                    footer={[
                        <Button key="back" onClick={this.closeModal.bind(this)}>
                            Close
                        </Button>,
                        <Button key="submit" type="primary" loading={this.isCreateLoading} onClick={this.saveTranslation.bind(this)}>
                            Submit
                        </Button>,
                    ]}
                >
                    <CreateTranslationForm onSubmit={this.onSubmit.bind(this)} submit={this.submitForm}/>
                </Modal>
            </Fragment>
        );
    }

    componentDidMount(): void {
        this.viewModel.loadTranslations(this.projectId);
    }

    private onTextUpdated(event: React.ChangeEvent<HTMLTextAreaElement>, translationKey: TranslationKey, lang: string) {
        const translation = translationKey.translations.find((t) => t.lang === lang);
        if (translation) {
            translation.text = event.currentTarget.value;
        } else {
            translationKey.translations.push({
                lang: lang,
                text: event.currentTarget.value,
            });
        }
    }

    private onTextBlur(event: React.ChangeEvent<HTMLTextAreaElement>, translationKey: TranslationKey, lang: string) {
        this.viewModel.updateTranslation(this.projectId, translationKey.key, lang, event.currentTarget.value);
    }

    private async autoTranslate(translationKey: TranslationKey) {
        await this.viewModel.autoTranslate(translationKey.key, this.selection1.key, this.selection2.key);
        const translation = translationKey.translations.find((t) => t.lang  === this.selection2.key);
        if (translation && translation.text) {
            await this.viewModel.updateTranslation(this.projectId, translationKey.key, this.selection2.key, translation.text);
        }
    }

    private updateTranslationKey(newKey: string, oldKey: string) {
        if (newKey) {
            this.viewModel.updateTranslationKey(newKey, oldKey);
        }
    }

    private deleteTranslation(translationKey: string) {
        this.viewModel.deleteTranslation(this.projectId, translationKey);
    }

    private addTranslation() {
        this.showCreateModal = true;
    }

    private onSubmit(err: any, key: string) {
        this.submitForm = false;

        if (err) {
            this.isCreateLoading = false;

            return;
        }

        this.viewModel.addTranslation(this.projectId, key)
            .then((success) => {
                this.isCreateLoading = false;

                if (success) {
                    this.closeModal();
                }
            });
    }

    private saveTranslation() {
        this.isCreateLoading = true;
        this.submitForm = true;
    }

    private closeModal() {
        this.showCreateModal = false;
    }

    private getTranslationForLang(lang: string, translationKey: TranslationKey): string {
        const translation = translationKey.translations.find((t) => t.lang === lang);
        if (translation && translation.text) {
            return translation.text;
        } else {
            return "";
        }
    }
}