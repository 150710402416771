import React, {Fragment} from 'react';
import {Empty, List, Typography} from "antd";
import {Link} from "react-router-dom";
import Project from "../../../../Models/Project";
import ProjectListCard from "./ProjectListCard";
import {Routing} from "../../../../Utils/Routing";

const {Text} = Typography;


interface ProjectListContentProps {
    projects: Project[];
    onDeleteClicked?: (projectId: number) => void;
}

export default class ProjectListContent extends React.PureComponent<ProjectListContentProps> {
    render(): React.ReactElement {
        if (this.props.projects.length) {
            return (
                <List
                    grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 2,
                        md: 3,
                        lg: 4,
                    }}
                    dataSource={this.props.projects}
                    renderItem={(item: Project) => (
                        <List.Item>
                            <ProjectListCard project={item} onDeleteClicked={this.props.onDeleteClicked} />
                        </List.Item>
                    )}
                />
            );
        } else {
            return (
                <Fragment>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />

                    <div style={{textAlign: "center"}}>
                        <Text type={"secondary"}>
                            <Link to={Routing.projectsCreate}>Create</Link> a project to fill the list
                        </Text>
                    </div>
                </Fragment>
            );
        }
    }
}