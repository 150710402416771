import {action, computed, observable} from "mobx";
import {Api} from "../../../Api/ApiManager";
import {TranslationKey} from "../../../Models/Translation";
import {notification} from "antd";

enum ProjectSingleFetchState {
    DONE,
    LOADING,
    FAILED,
}

export class ProjectSingleViewModel {
    @observable state = ProjectSingleFetchState.DONE;
    @observable translationKeys: TranslationKey[] = [];

    @computed
    get isLoading(): boolean {
        return this.state === ProjectSingleFetchState.LOADING;
    }

    @computed
    get isError(): boolean {
        return this.state === ProjectSingleFetchState.FAILED;
    }

    @action
    async loadTranslations(projectId: number) {
        this.state = ProjectSingleFetchState.LOADING;

        const response = await Api.project.getProjectTranslations(projectId);
        if (response.isOk) {
            this.translationKeys = response.body.data.map((t) => {
                return {
                    key: t.translationKey,
                    translations: t.translations.map(y => {
                        return {
                            lang: y.languageCode,
                            text: y.text,
                        };
                    }),
                };
            });

            this.state = ProjectSingleFetchState.DONE;
        } else {
            this.state = ProjectSingleFetchState.FAILED;
            if(response.isClientError) {
                alert("Woops! Something went wrong loading translation. Try again");
            } else {
                alert("Woops! Failed to load translations. Try again later");
            }
        }
    }

    @action
    async addTranslation(projectId: number, key: string): Promise<boolean> {
        const response = await Api.project.addOrUpdateTranslation(projectId, key);
        if (response.isOk) {
            this.translationKeys.push({
                key: response.body.data.translationKey,
                translations: response.body.data.translations.map(y => {
                    return {
                        lang: y.languageCode,
                        text: y.text,
                    };
                }),
            });

            return true;
        } else {
            if(response.isClientError) {
                alert("Woops! Something went wrong adding a translation. Try again");
            } else {
                alert("Woops! Failed to add translation. Try again later");
            }
            return false;
        }
    }

    @action
    async updateTranslation(projectId: number, key: string, lang: string, text: string) {
        const response = await Api.project.addOrUpdateTranslation(projectId, key, {
            languageCode: lang,
            text: text,
        });

        if (response.isOk) {
            const translationKey = this.translationKeys.find((tk) => tk.key === key);
            if (translationKey) {
                const translation = translationKey.translations.find((t) => t.lang === lang);
                if (translation) {
                    translation.text = text;
                } else {
                    translationKey.translations.push({
                        lang: lang,
                        text: text,
                    });
                }
            }
            this.updateNotification();
        } else {
            if (response.isClientError) {
                alert("Woops! Something went wrong updating a translation. Try again");
            } else {
                alert("Woops! Failed to update translation. Try again later");
            }
        }
    }

    private updateNotification() {
        notification.success({
            message: 'Progress saved',
            description: 'Your progress have been saved',
            placement: "bottomRight",
        });
    };

    @action
    updateTranslationKey(newKey: string, oldKey: string) {
        const translation = this.translationKeys.find((translation) => translation.key === oldKey);
        if (translation) {
            translation.key = newKey;
        }
    }

    @action
    async deleteTranslation(projectId: number, translationKey: string): Promise<boolean> {
        const response = await Api.project.deleteTranslation(projectId, translationKey);

        if (response.isOk) {
            const index = this.translationKeys.findIndex((translation) => translation.key === translationKey);
            if (index > -1) {
                this.translationKeys.splice(index, 1);
            }
        } else {
            if (response.isClientError) {
                alert("Woops! Something went wrong deleting a translation. Try again");
            } else {
                alert("Woops! Failed to delete a translation. Try again later");
            }
        }

        return response.isOk;
    }

    @action
    async autoTranslate(key: string, fromLang: string, toLang: string) {
        const translation = this.translationKeys.find((t) => t.key === key);
        if (!translation) {
            return;
        }

        const motherLang = translation.translations.find((t) => t.lang === fromLang);
        if (!motherLang || !motherLang.text) {
            return;
        }

        const response = await Api.translate.getAutoTranslation(motherLang.text, motherLang.lang, toLang);
        if (response.isOk) {
            const translation2 = translation.translations.find((t) => t.lang === toLang);
            if (translation2) {
                translation2.text = response.body.data.text;
            } else {
                translation.translations.push({
                    lang: toLang,
                    text: response.body.data.text,
                });
            }
        } else {
            if(response.isClientError) {
                alert("Woops! Something went wrong auto translating. Try again");
            } else {
                alert("Woops! Failed to auto translate. Try again alter");
            }
        }
    }
}