import {action, computed, observable} from "mobx";
import Project from "../../../Models/Project";
import {Api} from "../../../Api/ApiManager";

export enum FetchState {
    EMPTY,
    DONE,
    LOADING,
    FAILED,
}

export class ProjectListViewModel {
    @observable state: FetchState = FetchState.EMPTY;
    @observable projects: Project[] = [];

    @computed
    get totalCount(): number {
        return this.projects.length;
    }

    @action
    async loadProjects() {
        this.state = FetchState.LOADING;

        let projectsResponse = await Api.project.getProjects();
        if (projectsResponse.isOk) {
            this.projects = projectsResponse.body.data;
            this.state = FetchState.DONE;
        } else {
            this.state = FetchState.FAILED;
            if(projectsResponse.isClientError) {
                alert("Woops! Something went wrong loading projects. Try again");
            } else {
                alert("Woops! Failed to load projects. Try again later");
            }
        }
    }

    @action
    async deleteProject(id: number) {
        const response = await Api.project.deleteProject(id);
        if (response.isOk) {
            this.projects = this.projects.filter((project) => project.id !== id);
        } else {
            if (response.isClientError) {
                alert("Woops! Something went wrong deleting project. Try again");
            } else {
                alert("Woops! Failed to delete project. Try again later");
            }
        }
    }
}
