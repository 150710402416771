import {observer} from "mobx-react";
import React, {SyntheticEvent} from "react";
import {observable} from "mobx";
import {Input, Typography} from "antd";

const {Text} = Typography;

interface ClickToEditProps {
    value: string;
    onEndEdit: (newValue: string) => void;
}

@observer
export class ClickToEdit extends React.PureComponent<ClickToEditProps> {
    @observable
    private isEditing = false;

    private newValue: string = "";

    render(): React.ReactElement {
        return (
            <div onClick={this.startEditing.bind(this)}>
                {this.renderInput()}
            </div>
        );
    }

    private renderInput(): React.ReactElement {
        if (this.isEditing) {
            return <Input defaultValue={this.props.value} onBlur={this.endEditing.bind(this)} onChange={this.onTextUpdate.bind(this)}/>;
        } else {
            return <Text>{this.props.value} </Text>;
        }
    }

    private startEditing() {
        this.isEditing = true;
    }

    private onTextUpdate(event: SyntheticEvent<HTMLInputElement>) {
        this.newValue = event.currentTarget.value;
    }

    private endEditing() {
        this.isEditing = false;
        this.props.onEndEdit(this.newValue);
    }
}