import React, {FormEvent, PureComponent} from "react";
import {Form, Icon, Input, Typography} from "antd";
import {FormComponentProps} from "antd/es/form";

const {Text} = Typography;

type CreateTranslationFormInternalProps = {
    submit?: boolean;
    onSubmit: (err: any, key: string) => void;
} & FormComponentProps;

export class CreateTranslationFormInternal extends PureComponent<CreateTranslationFormInternalProps> {
    render(): React.ReactElement {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form onSubmit={this.handleSubmit.bind(this)} className="create-translation-form">
                <Text>Translation key:</Text>
                <Form.Item>
                    {getFieldDecorator('key', {
                        rules: [
                            { required: true, message: 'Please enter a translation key' },
                        ],
                    })(
                        <Input prefix={<Icon type="key" style={{ color: 'rgba(0, 0, 0, .25)' }} />} placeholder="Translation key"/>,
                    )}
                </Form.Item>
            </Form>
        );
    }

    componentDidUpdate(prevProps: Readonly<CreateTranslationFormInternalProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if (this.props.submit && !prevProps.submit) {
            this.processForm();
        }
    }

    private handleSubmit(event: FormEvent) {
        event.preventDefault();
        this.processForm();
    }

    private processForm() {
        this.props.form.validateFields(async (err, values) => {
            this.props.onSubmit(err, values.key);
            if (!err) {
                this.props.form.resetFields();
            }
        });
    }
}

export const CreateTranslationForm = Form.create<CreateTranslationFormInternalProps>()(CreateTranslationFormInternal);
