import React, {Fragment} from "react";
import {observer} from "mobx-react";
import {PageHeader} from "antd";
import {observable} from "mobx";
import {Redirect, RouteComponentProps} from "react-router-dom";
import {ProjectForm} from "./Components/ProjectForm";
import {FetchState, ProjectCreateEditViewModel} from "./ProjectCreateEditViewModel";
import {LoadingSpinner} from "../../../Components/LoadingSpinner";
import {route, Routing} from "../../../Utils/Routing";

type PageParams = {
    id?: string;
}

interface ProjectCreateEditProps extends RouteComponentProps<PageParams> {
}

@observer
export default class ProjectCreateEdit extends React.PureComponent<ProjectCreateEditProps> {
    @observable private viewModel = new ProjectCreateEditViewModel();

    @observable private disableSubmit = false;
    @observable private redirectToProjectId?: number;

    render(): React.ReactElement|string {
        if (this.redirectToProjectId) {
            return <Redirect to={route(Routing.project, this.redirectToProjectId)} />
        }

        return (
            <Fragment>
                <PageHeader title="Create project" />
                {this.buildPageContent()}
            </Fragment>
        );
    }

    componentDidMount(): void {
        if (this.props.match.params.id) {
            const id = parseInt(this.props.match.params.id);

            this.viewModel.loadProject(id)
                .then(() => console.log("Project loaded"));
        }
    }

    private buildPageContent(): React.ReactElement {
        if (this.viewModel.isLoading) {
            return <LoadingSpinner loading={true}/>;
        } else {
            return (
                <ProjectForm
                    disableSubmit={this.disableSubmit}
                    onSubmit={this.onSubmit.bind(this)}
                    onValidate={this.onValidate.bind(this)}
                    project={this.viewModel.project}
                />
            );
        }
    }

    private onSubmit() {
        this.disableSubmit = true;
    }

    private onValidate(err: object|null, values: {[key: string]: any}) {
        if (err) {
            this.disableSubmit = false;
        } else {
            this.viewModel.createOrUpdateProject(values.name)
                .then(() => {
                    this.disableSubmit = false;
                    if (this.viewModel.state === FetchState.DONE) {
                        this.redirectToProjectId = this.viewModel.project!.id;
                    }
                });
        }
    }
}