import {ClipLoader} from "react-spinners";
import React from "react";

interface LoadingSpinnerProps {
    loading: boolean;
}

export const LoadingSpinner = ({loading}: LoadingSpinnerProps) => {
    return (
        <div className={"loading-spinner"} style={{textAlign: "center", margin: "24px"}}>
            <ClipLoader
                sizeUnit={"px"}
                size={50}
                color={'#123abc'}
                loading={loading}
            />
        </div>
    );
};
