import {ApiHeaders} from "./types";
import {ApiRequest} from "./ApiRequest";
import {ApiResponse} from "./ApiResponse";
import {Config} from "../../Config";

export class ApiService {
    private readonly _baseUrl: string;
    private readonly _headers: ApiHeaders;
    private readonly _addAuth?: (request: ApiRequest<any>) => void;
    private readonly _retryHandler?: (request: ApiRequest<any>, response: ApiResponse<any>) => Promise<boolean>;

    constructor(baseUrl: string, headers: ApiHeaders = [], addAuth?: (request: ApiRequest<any>) => void, retryHandler?: (request: ApiRequest<any>, response: ApiResponse<any>) => Promise<boolean>) {
        this._baseUrl = baseUrl.endsWith("/") ? baseUrl.substring(0, baseUrl.length - 1) : baseUrl;
        this._headers = headers;
        this._addAuth = addAuth;
        this._retryHandler = retryHandler;
    }

    get baseUrl(): string {
        return this._baseUrl;
    }

    get headers(): ApiHeaders {
        return this._headers;
    }

    async execute<T, U>(request: ApiRequest<U>): Promise<ApiResponse<T>> {
        if (this._addAuth) {
            this._addAuth(request);
        }

        if (Config.isDevelopment()) {
            console.log(request);
        }

        let response = await fetch(`${this.baseUrl}${request.endpoint}`, {
            mode: "cors",
            method: request.method,
            headers: this.buildHeaders(request.headers),
            body: JSON.stringify(request.body),
        });

        let apiResponse = new ApiResponse<T>(response);
        await apiResponse.parseBody();

        if (Config.isDevelopment()) {
            console.log(apiResponse);
        }

        if (!apiResponse.isOk && this._retryHandler) {
            const shouldRetry = await this._retryHandler(request, apiResponse);
            if (shouldRetry) {
                return request.retry(this);
            }
        }

        return apiResponse;
    }

    private buildHeaders(extraHeaders: ApiHeaders): HeadersInit {
        return this.headers.concat(extraHeaders)
            .map((header) => [header.key, header.value]);
    }
}












