import React from 'react';
import {Switch, Route, BrowserRouter} from "react-router-dom";
import { Layout } from "antd";

import './App.css';
import {Header} from "./Components/Template/Header/Header";
import ProjectList from "./Pages/Projects/ProjectList/ProjectList";
import ProjectSingle from "./Pages/Projects/ProjectSingle/ProjectSingle";
import Account from "./Pages/Account/Account";
import {Provider} from "mobx-react";
import ProjectCreateEdit from "./Pages/Projects/ProjectCreateEdit/ProjectCreateEdit";
import Login from "./Pages/Log-in/Login";
import CreateUser from "./Pages/CreateUser/CreateUser";
import {appState} from "./AppState";
import {Api} from "./Api/ApiManager";
import {OAuth} from "./Pages/OAuth/OAuth";
import {OAuthContainer} from "./Utils/OAuth/OAuthContainer";
import {GitlabOAuthProvider} from "./Utils/OAuth/GitlabOAuthProvider";
import {Routing} from "./Utils/Routing";
import {GithubOAuthProvider} from "./Utils/OAuth/GithubOAuthProvider";

const { Content, Footer } = Layout;


// todo: this should at app start but not within view
if (appState.isLoggedIn) {
    Api.session.refreshToken(appState.session!.user.id, appState.session!.refreshToken)
        .then((result) => {
            if (result.isOk) {
                appState.setSession(result.body.data);
            } else {
                console.warn("User can't refresh");
                appState.logout();
            }
        });
}

// todo: this should at app start but not within view
const oauthProviders = new OAuthContainer();
oauthProviders.addProvider("gitlab", new GitlabOAuthProvider());
oauthProviders.addProvider("github", new GithubOAuthProvider());

const App: React.FC = () => {
  return (
    <BrowserRouter>
        <Provider appState={appState} oauthProviders={oauthProviders}>
            <Layout>
                <Header />

                <Content className="App-content">
                    <div>
                        <Switch>
                            <Route path={Routing.home} exact component={Login}/>

                            <Route path={Routing.projectsEdit} component={ProjectCreateEdit}/>
                            <Route path={Routing.projectsCreate} component={ProjectCreateEdit}/>
                            <Route path={Routing.project} component={ProjectSingle}/>
                            <Route path={Routing.projects} component={ProjectList}/>

                            <Route path={Routing.login} component={Login}/>
                            <Route path={Routing.signup} component={CreateUser}/>
                            <Route path={Routing.oauth} component={OAuth}/>

                            <Route path={Routing.account} component={Account}/>
                        </Switch>
                    </div>
                </Content>

                <Footer className="App-footer">
                    Created by <a href="https://www.inside.dtu.dk/da/dtuinside/generelt/telefonbog/person?id=128109&tab=0" target="_blank" rel="noopener noreferrer">Mathias Fager</a> & <a href="https://www.inside.dtu.dk/da/dtuinside/generelt/telefonbog/person?id=128158&tab=0" target="_blank" rel="noopener noreferrer">Magnus Hansen</a>
                </Footer>
            </Layout>
        </Provider>
    </BrowserRouter>
  );
};

export default App;
