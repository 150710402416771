import {action, computed, observable} from "mobx";
import Project from "../../../Models/Project";
import {Api} from "../../../Api/ApiManager";
import {ApiResponse} from "../../../Api/Library/ApiResponse";
import {ProjectResponseBody} from "../../../Api/Endpoints/ProjectApi";

export enum FetchState {
    DONE,
    LOADING,
    UPDATING,
    FAILED,
}

export class ProjectCreateEditViewModel {
    @observable state = FetchState.DONE;
    @observable project?: Project;

    @computed
    get isLoading(): boolean {
        return this.state === FetchState.LOADING;
    }

    @computed
    get isUpdating(): boolean {
        return this.state === FetchState.UPDATING;
    }

    @action
    async loadProject(id: number) {
        this.state = FetchState.LOADING;

        const response = await Api.project.getProject(id);
        if (response.isOk) {
            this.project = response.body.data;
            this.state = FetchState.DONE;
        } else {
            this.state = FetchState.FAILED;
            if (response.isClientError) {
                alert("Woops! Something went wrong loading projects. Try again");
            } else {
                alert("Woops! Failed to load projects. Try again later");
            }
        }
    }

    @action
    async createOrUpdateProject(name: string) {
        this.state = FetchState.UPDATING;

        let response: ApiResponse<ProjectResponseBody>;
        if (this.project) {
            response = await Api.project.updateProject(this.project.id, name);
        } else {
            response = await Api.project.createProject(name);
        }

        if (response.isOk) {
            this.project = response.body.data;
            this.state = FetchState.DONE;
        } else {
            this.state = FetchState.FAILED;
            if(response.isClientError) {
                alert("Woops! Something went wrong updating or creating a project. Try again");
            } else {
                alert("Woops! Failed to update or create project. Try again later");
            }
        }
    }
}
