import {OAuthProvider} from "./OAuthProvider";
import {Config} from "../../Config";
import React from "react";
import {Button} from "antd";

export class GitlabOAuthProvider implements OAuthProvider {
    private scopes = [
        "api",
        "read_user",
    ];

    getRedirectUrl(): string {
        return `${window.location.origin}${Config.gitlab.redirectPath}`;
    }

    getAuthorizationUrl(): string {
        return `https://gitlab.com/oauth/authorize?client_id=${Config.gitlab.appId}&redirect_uri=${this.getRedirectUrl()}&response_type=code&scope=${this.scopes.join(" ")}`;
    }

    createSigninElement(onClick: (provider: OAuthProvider) => void): React.ReactElement {
        return (
            <Button icon={"gitlab"} style={{backgroundColor: "orange", color: "white", width: "300px"}} onClick={() => onClick(this)}>
                Signin with Gitlab
            </Button>
        )
    }

    createSignupElement(onClick: (provider: OAuthProvider) => void): React.ReactElement {
        return (
            <Button icon={"gitlab"} style={{backgroundColor: "orange", color: "white", width: "300px"}} onClick={() => onClick(this)}>
                Signup with Gitlab
            </Button>
        );
    }
}