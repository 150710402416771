import {ApiService} from "./Library/ApiService";
import {SessionApi} from "./Endpoints/SessionApi";
import {Config} from "../Config";
import {UserApi} from "./Endpoints/UserApi";
import {ProjectApi} from "./Endpoints/ProjectApi";
import {appState} from "../AppState";
import {AutoTranslationApi} from "./Endpoints/AutoTranslationApi";

class ApiManager {
    private readonly api = new ApiService(Config.apiUrl, [
        {
            key: "Content-Type",
            value: "application/json",
        },
    ], (request) => {
        if (appState.session) {
            request.withHeaders([
                {key: "Authorization", value: `Bearer ${appState.session.accessToken}`}
            ]);
        }
    }, async (request, response) => {
        if (request.retryCount === 0 && response.status === 401) {
            const session = appState.session;
            if (session) {
                const response = await Api.session.refreshToken(session.user.id, session.refreshToken);
                if (response.isOk) {
                    appState.setSession(response.body.data);
                    return true;
                }
            }
        }

        return false;
    });

    readonly session = new SessionApi(this.api);
    readonly user = new UserApi(this.api);
    readonly project = new ProjectApi(this.api);
    readonly translate = new AutoTranslationApi(this.api);
}

export const Api = new ApiManager();
