import {action, computed, observable} from "mobx";
import {Session} from "./Models/Session";

export class AppState {
    private static readonly STORAGE_KEY = "session";

    @observable
    session?: Session;

    @computed
    get isLoggedIn(): boolean {
        return this.session !== undefined;
    }

    constructor() {
        const session = localStorage.getItem(AppState.STORAGE_KEY);
        if (session) {
            const parsedSession = JSON.parse(session);

            // todo: temp - remove session if not convertible
            if (parsedSession.user) {
                this.session = parsedSession;
            } else {
                this.logout();
            }
        }
    }

    @action
    setSession(session: Session) {
        this.session = session;
        localStorage.setItem(AppState.STORAGE_KEY, JSON.stringify(session));
    }

    @action
    logout() {
        this.session = undefined;
        localStorage.removeItem(AppState.STORAGE_KEY);
    }
}

export const appState = new AppState();