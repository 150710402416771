import React, {FormEvent} from "react";
import {Button, Form, Input} from "antd";
import {FormComponentProps} from "antd/es/form";
import Project from "../../../../Models/Project";

interface ProjectFormProps extends FormComponentProps {
    disableSubmit: boolean;
    onSubmit: () => void;
    onValidate: (err: object|null, values: {[key: string]: any}) => void;
    project?: Project;
}

class ProjectFormInternal extends React.PureComponent<ProjectFormProps> {
    render(): React.ReactElement {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form onSubmit={this.onSubmit.bind(this)}>
                <Form.Item>
                    {getFieldDecorator('name', {
                        rules: [
                            { required: true, message: 'Please enter a project name!' },
                            { max: 255, message: "A project name cannot be longer than 255."}
                        ],
                        initialValue: this.props.project ? this.props.project.name : null,
                    })(
                        <Input style={{maxWidth: "320px"}} autoFocus={true} placeholder="Project name" />
                    )}
                </Form.Item>

                <Button loading={this.props.disableSubmit} type={"primary"} htmlType="submit">{this.props.project ? "Save" : "Create"}</Button>
                <Button type={"ghost"} style={{ marginLeft: 8 }} onClick={this.handleReset.bind(this)}>Reset</Button>
            </Form>
        );
    }

    private onSubmit(event: FormEvent) {
        event.preventDefault();

        this.props.onSubmit();
        this.props.form.validateFields((err, values) => {
            this.props.onValidate(err, values);
        });
    }

    private handleReset() {
        this.props.form.resetFields();
    }
}

export const ProjectForm = Form.create<ProjectFormProps>()(ProjectFormInternal);
