import React from 'react';
import {Menu, Dropdown, Icon, Button} from 'antd';
import {observer} from "mobx-react";
import {observable} from "mobx";
import {ClickParam} from "antd/es/menu";

export type DropdownMenuItem = {
    key: string;
    text: string;
};

type DropDownProps = {
    items: DropdownMenuItem[];
    selected?: DropdownMenuItem|number;
    itemWasSelected?: (item: DropdownMenuItem) => void;
};

@observer
export default class DropDown extends React.PureComponent<DropDownProps> {
    @observable
    private selected?: DropdownMenuItem;


    constructor(props: DropDownProps) {
        super(props);

        const selected = this.props.selected;
        if (selected) {
            if (typeof selected === "number") {
                this.selected = this.props.items[selected];
            } else {
                this.selected = selected;
            }
        }
    }

    render(): React.ReactElement {
        return (
            <Dropdown overlay={this.renderMenu()} trigger={["click"]}>
                <Button>
                    {this.selected ? this.selected.text : "Language"}
                    <Icon type="down"/>
                </Button>
            </Dropdown>
        );
    }

    private renderMenu(): React.ReactElement {
        return (
            <Menu>
                {this.props.items.map((item) => (
                    <Menu.Item key={item.key} onClick={this.handleChange.bind(this)} >
                        {item.text}
                    </Menu.Item>
                ))}
            </Menu>
        );
    }

    private handleChange(event: ClickParam) {
        this.selected = this.props.items.find((item) => item.key === event.key);
        if (this.props.itemWasSelected) {
            this.props.itemWasSelected(this.selected!);
        }
    }
}