import {ApiService} from "../Library/ApiService";
import {ApiResponse} from "../Library/ApiResponse";
import {ApiRequest} from "../Library/ApiRequest";
import {ApiEndpoints} from "../ApiEndpoints";
import User from "../../Models/User";

interface LoginRequestBody {
    email: string;
    password: string;
}

interface RefreshTokenRequestBody {
    refreshToken: string;
}

interface OAuthRequestBody {
    code: string;
    redirectUrlUsed: string;
}

interface LoginResponseBody {
    accessToken: string;
    refreshToken: string;
    user: User;
}

export class SessionApi extends ApiEndpoints {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(service: ApiService) {
        super(service);
    }

    login(email: string, password: string): Promise<ApiResponse<LoginResponseBody>> {
        return new ApiRequest<LoginRequestBody>("POST", "/sessions")
            .withBody({
                email: email,
                password: password,
            })
            .execute(this.service);
    }

    refreshToken(userId: number, refreshToken: string): Promise<ApiResponse<LoginResponseBody>> {
        return new ApiRequest<RefreshTokenRequestBody>("PATCH", `/sessions/${userId}`)
            .withBody({refreshToken})
            .execute(this.service);
    }

    oauth(provider: string, code: string, redirectUrlUsed: string): Promise<ApiResponse<LoginResponseBody>> {
        return new ApiRequest<OAuthRequestBody>("POST", `/oauth/${provider.toLowerCase()}`)
            .withBody({code, redirectUrlUsed})
            .execute(this.service);
    }
}