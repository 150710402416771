import {FormComponentProps} from "antd/es/form";
import React, {FormEvent, KeyboardEvent, PureComponent} from "react";
import './LoginForm.css';
import {Button, Form, Icon, Input} from "antd";
import {observer} from "mobx-react";
import {Link} from "react-router-dom";
import {observable} from "mobx";
import {Routing} from "../../Utils/Routing";

interface LoginFormProps extends FormComponentProps {
    onFormValid: (values: {email:string, password:string}) => Promise<void>;
}

@observer
export class LoginForm extends PureComponent<LoginFormProps> {
    @observable private isLoading = false;

    render(): React.ReactElement {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form className="login-form"
                  onSubmit={this.handleSubmit.bind(this)}
                  onKeyPress={this.handleKeyBoardEvent.bind(this)}
            >
                <Form.Item>
                    {getFieldDecorator('email', {
                        rules: [{ required: true, message: 'Please input your email!' }],
                    })(
                        <Input
                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            placeholder="Email"
                        />,
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('password', {
                        rules: [
                            { required: true, message: 'Please input your Password!' },
                            { min: 5, message: "Password must be at least 5 characters long!" },
                        ],
                    })(
                        <Input
                            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            type="password"
                            placeholder="Password"
                        />,
                    )}
                </Form.Item>

                <Button loading={this.isLoading} type="primary" htmlType="submit" className="login-form-button">
                    Log in
                </Button>
                Or <Link to={Routing.signup}>register now!</Link>

                <Link to={Routing.home} className="login-form-forgot">Forgot password</Link>
            </Form>
        );
    }

    private async submit() {
        if (this.isLoading) {
            return;
        }

        this.isLoading = true;

        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                await this.props.onFormValid(values);
                this.isLoading = false;
            }
        });
    }

    private handleKeyBoardEvent(event: KeyboardEvent<HTMLFormElement>) {
        // enter
        if (event.keyCode === 13) {
            this.submit();
        }
    }

    private handleSubmit(event: FormEvent) {
        event.preventDefault();
        this.submit();
    }
}

const WrappedLoginForm = Form.create<LoginFormProps>()(LoginForm);

export default WrappedLoginForm;