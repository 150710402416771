import {OAuthProvider} from "./OAuthProvider";

export class OAuthContainer {
    private providers: {[key: string]: OAuthProvider} = {};

    addProvider(name: string, provider: OAuthProvider) {
        this.providers[name] = provider;
    }

    getProvider(name: string): OAuthProvider {
        return this.providers[name];
    }

    isValidProvider(name: string): boolean {
        return Object.keys(this.providers).includes(name);
    }

    map<T>(mapper: (value: [string, OAuthProvider], index: number, array: [string, OAuthProvider][]) => T): T[] {
        return Object.entries(this.providers).map(mapper);
    }
}