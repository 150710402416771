export interface DataContainer<T> {
    data: T;
}

export class ApiResponse<T> {
    private readonly _response: Response;

    private parsedBody?: DataContainer<T>;

    constructor(response: Response) {
        this._response = response;
    }

    get headers(): Headers {
        return this._response.headers;
    }

    get status(): number {
        return this._response.status;
    }

    get isOk(): boolean {
        return this._response.ok;
    }

    get isClientError(): boolean {
        return this.status >= 400 && this.status < 500;
    }

    get body(): DataContainer<T> {
        return this.parsedBody!;
    }

    async parseBody() {
        if (! this.parsedBody) {
            let contentType = this.headers.get("Content-Type");
            if (! contentType) {
                this.parsedBody = await this._response.text() as unknown as DataContainer<T>;
            } else if (contentType.match(/application\/([a-z]+\+)?json/)) {
                this.parsedBody = await this._response.json();
            } else {
                this.parsedBody = await this._response.text() as unknown as DataContainer<T>;
            }
        }
    }
}