import React from 'react';
import {inject, observer} from "mobx-react";
import {AppState} from "../../AppState";
import {Button} from "antd";
import {observable} from "mobx";
import {Redirect} from "react-router-dom";
import {Routing} from "../../Utils/Routing";

type AccountProps = {
    appState?: AppState;
}

@inject("appState")
@observer
export default class Account extends React.Component<AccountProps> {
    @observable
    private redirectToLogin = false;

    render(): React.ReactElement {
        if (this.redirectToLogin) {
            return <Redirect to={Routing.login}/>
        }

        const {user} = this.props.appState!.session!;

        return (
            <React.Fragment>
                <h1>Account for user {user.firstName} {user.lastName}</h1>

                <Button onClick={this.onLogoutClicked.bind(this)}>Logout</Button>
            </React.Fragment>
        );
    }

    private onLogoutClicked() {
        this.props.appState!.logout();
        this.redirectToLogin = true;
    }
}