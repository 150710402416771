import React from 'react';
import {observer, Provider} from "mobx-react";
import {FetchState, ProjectListViewModel} from "./ProjectListViewModel";
import {Button, PageHeader, Typography} from "antd";
import ProjectListContent from "./Components/ProjectListContent";
import {Link} from "react-router-dom";
import {LoadingSpinner} from "../../../Components/LoadingSpinner";
import {Routing} from "../../../Utils/Routing";

const {Text} = Typography;

@observer
export default class ProjectList extends React.PureComponent {
    private viewModel = new ProjectListViewModel();

    render(): React.ReactElement {
        return (
            <Provider projectViewModel={this.viewModel}>
                <PageHeader
                    title="Projects"
                    subTitle={`With a total count of ${this.viewModel.totalCount}`}
                    extra={(
                        <Button type={"primary"}>
                            <Link to={Routing.projectsCreate}>Create project</Link>
                        </Button>
                    )}
                />

                {this.renderPageContent()}
            </Provider>
        );
    }

    componentDidMount(): void {
        this.viewModel.loadProjects()
            .then(() => console.log("Projects loaded"));
    }

    private renderPageContent() {
        if (this.viewModel.state === FetchState.DONE) {
            return <ProjectListContent projects={this.viewModel.projects} onDeleteClicked={this.deleteProject.bind(this)}/>;
        } else if (this.viewModel.state === FetchState.FAILED) {
            return (
                <div style={{textAlign: "center"}}>
                    <Text style={{color: "red"}}>A unknown error appeared... Please try again.</Text>
                </div>
            );
        } else {
            return <LoadingSpinner loading={true}/>;
        }
    }

    private deleteProject(projectId: number) {
        this.viewModel.deleteProject(projectId)
            .then(() => console.log("Project deleted"));
    }
}