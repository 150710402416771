import {ApiResponse} from "./ApiResponse";
import {ApiHeaders, ApiRequestMethod} from "./types";
import {ApiService} from "./ApiService";

export class ApiRequest<T> {
    private readonly _method: ApiRequestMethod;
    private readonly _endpoint: string;

    private _headers: ApiHeaders;
    private _body?: T;

    private _retryCount = 0;

    get retryCount(): number {
        return this._retryCount;
    }

    constructor(method: ApiRequestMethod, endpoint: string, headers: ApiHeaders = [], body: T|undefined = undefined) {
        this._method = method;
        this._endpoint = endpoint.startsWith("/") ? endpoint : `/${endpoint}`;
        this._headers = headers;
        this._body = body;
    }

    get method(): ApiRequestMethod {
        return this._method;
    }

    get endpoint(): string {
        return this._endpoint;
    }

    get headers(): ApiHeaders {
        return this._headers;
    }

    get body(): T|undefined {
        return this._body;
    }

    withHeaders(headers: ApiHeaders): ApiRequest<T> {
        const headerKeys = headers.map((header) => header.key);
        this._headers = [...headers, ...this._headers.filter((header) => headerKeys.indexOf(header.key) < 0)];

        return this;
    }

    withBody(body: T): ApiRequest<T> {
        this._body = body;

        return this;
    }

    retry<R>(apiService: ApiService): Promise<ApiResponse<R>> {
        this._retryCount++;

        return this.execute(apiService);
    }

    execute<R>(apiService: ApiService): Promise<ApiResponse<R>> {
        return apiService.execute<R, T>(this);
    }
}