export class Config {
    static env: string = process.env.NODE_ENV!;
    static apiUrl: string = process.env.REACT_APP_API_URL!;

    static get enableCrashLog(): boolean {
        return process.env.REACT_APP_ENABLE_CRASH_LOG! === "true";
    }

    static gitlab = {
        appId: process.env.REACT_APP_GITLAB_APP_ID!,
        redirectPath: process.env.REACT_APP_GITLAB_REDIRECT_PATH!,
    };

    static github = {
        appId: process.env.REACT_APP_GITHUB_APP_ID!,
        redirectPath: process.env.REACT_APP_GITHUB_REDIRECT_PATH!,
    };

    static isProduction(): boolean {
        return Config.env.toLowerCase() === "production"
    }

    static isDevelopment(): boolean {
        return ! Config.isProduction()
    }
}