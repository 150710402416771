import {OAuthProvider} from "./OAuthProvider";
import {Config} from "../../Config";
import React from "react";
import {Button} from "antd";

export class GithubOAuthProvider implements OAuthProvider {
    private scopes = [
        "read:user",
        "user:email"
    ];

    getRedirectUrl(): string {
        return `${window.location.origin}${Config.github.redirectPath}`;
    }

    getAuthorizationUrl(): string {
        return `https://github.com/login/oauth/authorize?client_id=${Config.github.appId}&redirect_uri=${this.getRedirectUrl()}&scope=${this.scopes.join(" ")}`;
    }

    createSigninElement(onClick: (provider: OAuthProvider) => void): React.ReactElement {
        return (
            <Button icon={"github"} style={{backgroundColor: "black", color: "white", width: "300px"}} onClick={() => onClick(this)}>
                Signin with Github
            </Button>
        )
    }

    createSignupElement(onClick: (provider: OAuthProvider) => void): React.ReactElement {
        return (
            <Button icon={"github"} style={{backgroundColor: "black", color: "white", width: "300px"}} onClick={() => onClick(this)}>
                Signup with Github
            </Button>
        );
    }
}