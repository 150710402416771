import {ApiEndpoints} from "../ApiEndpoints";
import {ApiService} from "../Library/ApiService";
import {ApiResponse} from "../Library/ApiResponse";
import {ApiRequest} from "../Library/ApiRequest";

interface UserApiRequestBody {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
}

interface UserApiResponseBody {
    id: number;
    firstName: string;
    lastName: string;
    fullName: string;
    email: string;
}

export class UserApi extends ApiEndpoints {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(service: ApiService) {
        super(service);
    }

    createUser(body: UserApiRequestBody) : Promise<ApiResponse<UserApiResponseBody>> {
        return new ApiRequest<UserApiRequestBody>("POST", "/users")
            .withBody(body)
            .execute(this.service);

    }


}