import React, {FormEvent, PureComponent} from "react";
import {Button, Checkbox, Form, Input, Modal} from "antd";
import {FormComponentProps} from "antd/es/form";
import {observer} from "mobx-react";
import {observable} from "mobx";

interface CreateAccountFormProps extends FormComponentProps {
    onFormValid: (values: CreateUserFormValues) => void;
}

export type CreateUserFormValues = {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
};

@observer
export class CreateUserForm extends PureComponent<CreateAccountFormProps> {
    state = {
        confirmDirty: false,
    };

    @observable private showModal = false;

    render(): React.ReactElement {
        const {getFieldDecorator} = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };

        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                <Form.Item label="E-mail">
                    {getFieldDecorator('email', {
                        rules: [
                            {
                                type: 'email',
                                message: 'The input is not valid E-mail!',
                            },
                            {
                                required: true,
                                message: 'Please input your E-mail!',
                            },
                        ],
                    })(<Input/>)}
                </Form.Item>
                <Form.Item label="Password" hasFeedback>
                    {getFieldDecorator('password', {
                        rules: [
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                            {
                                validator: this.validateToNextPassword,
                            },
                        ],
                    })(<Input.Password/>)}
                </Form.Item>
                <Form.Item label={"Password 2"} hasFeedback>
                    {getFieldDecorator('confirm', {
                        rules: [
                            {
                                required: true,
                                message: 'Please confirm your password!',
                            },
                            {
                                validator: this.compareToFirstPassword,
                            },
                        ],
                    })(<Input.Password onBlur={this.handleConfirmBlur}/>)}
                </Form.Item>
                <Form.Item label={
                    <span>First name</span>
                }>
                    {getFieldDecorator('firstName', {
                        rules: [{required: true, message: 'Please input your first name!', whitespace: true}],
                    })(<Input/>)}
                </Form.Item>
                <Form.Item label={
                    <span>Last name</span>
                }>
                    {getFieldDecorator('lastName', {
                        rules: [{required: true, message: 'Please input your last name!', whitespace: true}],
                    })(<Input/>)}
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                    {getFieldDecorator('agreement', {
                        valuePropName: 'checked',
                        rules: [{required: true, message: 'You must accept the EULA'}]
                    })(
                        <Checkbox>
                            I have read the <Button onClick={() => this.showModal = true}
                                                    style={{margin: 0, padding: 0}} type="link">agreement</Button>
                        </Checkbox>,
                    )}
                </Form.Item>
                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit">
                        Register
                    </Button>
                </Form.Item>

                <Modal
                    footer={
                        <Button type={"primary"} onClick={() => this.showModal = false}>OK</Button>
                    }
                    maskClosable={true}
                    title="Privacy policy"
                    visible={this.showModal}
                >
                    <p>Privacy Policy
                        This Privacy Policy describes how your personal information is collected, used, and shared when
                        you use Langustr (the “App”).

                        PERSONAL INFORMATION WE COLLECT
                        When you use the App, we automatically collect certain information about your device, including
                        information about your phone, IP address, time zone.

                        We refer to this automatically-collected information as “Device Information.”

                        We collect Device Information using the following technologies:

                        “Log files” track actions occurring on the App, and collect data including your IP address,
                        phone
                        type, Internet service provider, and date/time stamps.

                        When we talk about “Personal Information” in this Privacy Policy, we are talking about Device
                        Information.

                        HOW DO WE USE YOUR PERSONAL INFORMATION?
                        We use the Device Information that we collect to help us screen for potential risk and fraud (in
                        particular, your IP address), and more generally to improve and optimize our App (for example,
                        by
                        generating analytics about how our customers browse and interact with the App).

                        SHARING YOUR PERSONAL INFORMATION
                        We share your Personal Information with third parties to help us use your Personal Information,
                        as
                        described above.

                        Finally, we may also share your Personal Information to comply with applicable laws and
                        regulations,
                        to respond to a subpoena, search warrant or other lawful request for information we receive, or
                        to
                        otherwise protect our rights.

                        YOUR RIGHTS
                        If you are a European resident, you have the right to access personal information we hold about
                        you
                        and to ask that your personal information be corrected, updated, or deleted.

                        If you would like to exercise this right, please contact us through the contact information
                        below.

                        DATA RETENTION
                        When you signup for the app, we will maintain your Personal Information for our records unless
                        and
                        until you ask us to delete this information.

                        CHANGES
                        We may update this privacy policy from time to time in order to reflect, for example, changes to
                        our
                        practices or for other operational, legal or regulatory reasons.

                        CONTACT US
                        For more information about our privacy practices, if you have questions, or if you would like to
                        make a complaint, please contact us by e-mail at info@langustr.com.</p>
                </Modal>
            </Form>
        );
    }

    private handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll(async (err, values) => {
            if (!err) {
                this.props.onFormValid(values);
                console.log('Received values of form: ', values);
            }
        });
    };

    private handleConfirmBlur = (e: { target: { value: any; }; }) => {
        const {value} = e.target;
        this.setState({confirmDirty: this.state.confirmDirty || !!value});
    };

    private compareToFirstPassword = (rule: any, value: any, callback: any) => {
        const {form} = this.props;
        if (value && value !== form.getFieldValue('password')) {
            callback('Two passwords that you enter is inconsistent!');
        } else {
            callback();
        }
    };

    private validateToNextPassword = (rule: any, value: any, callback: any) => {
        const {form} = this.props;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], {force: true});
        }
        callback();
    };
}

const WrappedCreateAccountForm = Form.create<CreateAccountFormProps>({name: 'create-account'})(CreateUserForm);

export default WrappedCreateAccountForm;