export enum Routing {
    home = "/",

    login = "/login",
    signup = "/create-user",
    oauth = "/oauth/:provider",

    account = "/account",

    projects = "/projects",
    projectsCreate = "/projects/create",
    project = "/projects/:id",
    projectsEdit = "/projects/edit/:id",
}

export function route(route: Routing, ...params: any[]) {
    let paramCount = 0;

    if (params.length < (route.match(/:/g) || []).length) {
        throw new RouteError(route);
    }

    return route.split("/")
        .map((val) => val.startsWith(":") ? params[paramCount++] : val)
        .join("/");
}

export class RouteError extends Error {
    constructor(route: Routing) {
        super(`Wrong amount of params for '${route}'`);
    }
}