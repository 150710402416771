import React, {PureComponent} from 'react';
import './Header.css';
import logo from './header_logo.svg';
import {Link} from 'react-router-dom';
import {Avatar, Icon, Layout, Menu, Typography} from "antd";
import {AppState} from "../../../AppState";
import {inject, observer} from "mobx-react";
import {Routing} from "../../../Utils/Routing";

const { Text } = Typography;

type HeaderProps = {
    appState?: AppState
};

type MenuItem = {
    name: string;
    path: Routing;
    icon?: string;
}

@inject("appState")
@observer
export class Header extends PureComponent<HeaderProps> {
    render(): React.ReactElement {
        return (
            <Layout.Header className="Header-container">
                <div className="Header-logo">
                    <Link to={Routing.home}>
                        <img src={logo} alt="Langustr logo"/>
                    </Link>
                    <h1>Langustr</h1>
                </div>

                {/* todo: determine selected key based on path */}
                <Menu className="Header-menu"
                      theme="dark"
                      mode="horizontal"
                      defaultSelectedKeys={['0']}
                >
                    {this.getMenuItems().map((menu, index) => (
                        <Menu.Item key={index}>
                            {menu.icon ? <Icon type={menu.icon}/> : null}
                            <span>{menu.name}</span>
                            <Link to={menu.path}/>
                        </Menu.Item>
                    ))}
                </Menu>

                {this.renderRightSide()}
            </Layout.Header>
        )
    }

    private getMenuItems(): MenuItem[] {
            let menuItems: MenuItem[] = [];
        if (!this.props.appState!.isLoggedIn) {
            menuItems.concat([
                {
                    name: "Home",
                    path: Routing.home,
                    icon: "home",
                },
        ]);
        } else {
            menuItems = menuItems.concat([
                {
                    name: "Projects",
                    path: Routing.projects,
                    icon: "project",
                },
                // {
                //     name: "Teams",
                //     path: "/teams",
                //     icon: "team",
                // },
                // {
                //     name: "Translations",
                //     path: "/translations",
                // },
            ]);
        }

        return menuItems;
    }

    private renderRightSide(): React.ReactElement {
        if (this.props.appState!.isLoggedIn) {
            return (
                <Link to={Routing.account}>
                    <Avatar className="Header-avatar" size="large">{this.getUserInitials()}</Avatar>
                </Link>
            );
        } else {
            return (
                <Link to={Routing.login}>
                    <Text style={{color: "white"}}>
                        <Icon type="login"/> Login
                    </Text>
                </Link>
            );
        }
    }

    private getUserInitials(): string {
        const user = this.props.appState!.session!.user;

        return `${user.firstName.substring(0, 1).toUpperCase()}${user.lastName.substring(0, 1).toUpperCase()}`;
    }
}
