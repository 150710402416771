import React from 'react';
import {Button, Card, Icon, Statistic} from "antd";
import {Link} from "react-router-dom";
import Project from "../../../../Models/Project";
import {route, Routing} from "../../../../Utils/Routing";


interface ProjectListCardProps {
    project: Project;
    onDeleteClicked?: (projectId: number) => void;
}

export default class ProjectListCard extends React.PureComponent<ProjectListCardProps> {
    render(): React.ReactElement {
        return (
            <Card title={(
                <Link to={route(Routing.project, this.props.project.id)}>{this.props.project.name}</Link>
            )}>
                <Statistic title="Translation keys" value={this.props.project.translationKeysCount} />
                <Statistic title="Progress" value={this.props.project.percentageCompleted} suffix={"%"} precision={2} />

                <div style={{display: "flex", justifyContent: "flex-end", alignItems: "center", marginTop: '16px'}}>
                    <Link to={route(Routing.projectsEdit, this.props.project.id)}>
                        <Icon type={"edit"} style={{ fontSize: '24px', color: '#08c' }} />
                    </Link>
                    <Button onClick={this.onDeleteProject.bind(this)} size={"large"} type={"link"} icon={"delete"} style={{ fontSize: '24px', color: '#cc3538', marginLeft: '8px' }} />
                </div>
            </Card>
        );
    }

    private onDeleteProject() {
        if (this.props.onDeleteClicked) {
            this.props.onDeleteClicked(this.props.project.id);
        }
    }
}