import {ApiEndpoints} from "../ApiEndpoints";
import {ApiService} from "../Library/ApiService";
import {ApiResponse} from "../Library/ApiResponse";
import {ApiRequest} from "../Library/ApiRequest";

export interface AutoTranslationResponseBody {
    text: string
}

export class AutoTranslationApi extends ApiEndpoints {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(service: ApiService) {
        super(service);
    }

    getAutoTranslation(text: string, from: string, to: string): Promise<ApiResponse<AutoTranslationResponseBody>> {
        return new ApiRequest("GET", `/autotranslate?text=${encodeURI(text)}&from=${from}&to=${to}`)
            .execute(this.service);
    }
}