import {ApiService} from "../Library/ApiService";
import {ApiResponse} from "../Library/ApiResponse";
import {ApiRequest} from "../Library/ApiRequest";
import {ApiEndpoints} from "../ApiEndpoints";

export interface ProjectResponseBody {
    id: number;
    name: string;
    languages: number; // todo: relation to languages
    translationKeysCount: number;
    percentageCompleted: number;
}

export interface ProjectTranslationsResponseBody {
    translationKey: string;
    translations: [{languageCode: string, text?: string}]
}

interface ProjectTranslationPutBody {
    languageCode?: string;
    text?: string;
}

interface ProjectPostBody {
    name: string;
}

export class ProjectApi extends ApiEndpoints {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(service: ApiService) {
        super(service);
    }

    getProjects(): Promise<ApiResponse<ProjectResponseBody[]>> {
        return new ApiRequest("GET", "/projects")
            .execute(this.service);
    }

    getProject(id: number): Promise<ApiResponse<ProjectResponseBody>> {
        return new ApiRequest("GET", `/projects/${id}`)
            .execute(this.service);
    }

    createProject(name: string): Promise<ApiResponse<ProjectResponseBody>> {
        return new ApiRequest<ProjectPostBody>("POST", "/projects")
            .withBody({
                name,
            })
            .execute(this.service);
    }

    updateProject(id: number, name: string): Promise<ApiResponse<ProjectResponseBody>> {
        return new ApiRequest<ProjectPostBody>("PUT", `/projects/${id}`)
            .withBody({
                name,
            })
            .execute(this.service);
    }

    deleteProject(id: number): Promise<ApiResponse<ProjectResponseBody>> {
        return new ApiRequest<ProjectPostBody>("DELETE", `/projects/${id}`)
            .execute(this.service);
    }

    getProjectTranslations(id: number): Promise<ApiResponse<ProjectTranslationsResponseBody[]>> {
        return new ApiRequest("GET", `/projects/${id}/translations`)
            .execute(this.service);
    }

    addOrUpdateTranslation(id: number, translationKey: string, body: ProjectTranslationPutBody = {}): Promise<ApiResponse<ProjectTranslationsResponseBody>> {
        return new ApiRequest<ProjectTranslationPutBody>("PUT", `/projects/${id}/translations/${translationKey}`)
            .withBody(body)
            .execute(this.service);
    }

    deleteTranslation(id: number, translationKey: string) {
        return new ApiRequest<ProjectPostBody>("DELETE", `/projects/${id}/translations/${translationKey}`)
            .execute(this.service);
    }
}