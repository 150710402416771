import React from "react";
import WrappedLoginForm from "./LoginForm";
import {observable} from "mobx";
import {Redirect} from "react-router-dom";
import {Api} from "../../Api/ApiManager";
import {inject, observer} from "mobx-react";
import {FormComponentProps} from "antd/es/form";
import {OAuthContainer} from "../../Utils/OAuth/OAuthContainer";
import {OAuthProvider} from "../../Utils/OAuth/OAuthProvider";
import {AppState} from "../../AppState";
import {Routing} from "../../Utils/Routing";

interface LoginProps extends FormComponentProps {
    appState?: AppState;
    oauthProviders?: OAuthContainer;
}

@inject("appState")
@inject("oauthProviders")
@observer
export default class Login extends React.Component<LoginProps> {
    @observable redirect?: boolean;

    render(): React.ReactElement {
        if (this.redirect || this.props.appState!.isLoggedIn) {
            return <Redirect to={Routing.projects} />
        }

        return (
            <React.Fragment>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <WrappedLoginForm onFormValid={this.onFormValid.bind(this)}/>
                </div>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{display: 'flex', flexDirection: "column", alignItems: "center"}}>
                        {this.props.oauthProviders!.map(([_, provider], i) => (
                            <div key={i} style={{marginBottom: "8px"}}>
                                {provider.createSigninElement(this.startOAuthFlow.bind(this))}
                            </div>
                        ))}
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private startOAuthFlow(provider: OAuthProvider) {
        window.location.href = provider.getAuthorizationUrl();
    }

    private async onFormValid(values: {email: string, password: string}) {
        let response = await Api.session.login(values.email, values.password);
        if (response.isOk) {
            this.props.appState!.setSession(response.body.data);
            this.login();
        } else {
            if(response.isClientError) {
                alert("Woops! Something went wrong logging you in. Try again");
            } else {
                alert("Woops! Failed to log you in. Try again later");
            }
        }
    }

    private login() {
        this.redirect = true;
    }
}